<script setup>
import TopBar from "~/components/organisms/TopBar.vue";
import Header from "~/components/organisms/Header.vue";
import {defineAsyncComponent} from 'vue'
import FooterBlock from "~/components/organisms/FooterBlock.vue";
import {useStartupStore} from "~/store/sturtupStore";
import {useCartStore} from "~/store/cartStore.js";
import {useQuotationStore} from "~/store/quotationStore.js";

const startupStore = useStartupStore();
const cartStore = useCartStore();
const quotationStore = useQuotationStore();

const SideMenu = defineAsyncComponent({
  loader: () => import('~/components/organisms/SideMenu.vue'),
})
const Quotation = defineAsyncComponent({
  loader: () => import('~/components/organisms/Quotation.vue'),
})

useHead({
  meta: [
    {name: 'robots', content: 'index, follow'},
    {
      hid: 'og:locale',
      property: 'og:locale',
      content: 'sv'
    },
    {
      hid: 'og:site_name',
      property: 'og:site_name',
      content: 'Hill Ceramic'
    },
  ],
  script: [
    {
      type: 'application/ld+json',
      children: {
        "@context": "https://schema.org",
        "@type": "Organization",
        "url": "https://hillceramic.se/",
        "logo": "https://hillceramic.se/img/Hill Ceramic Logo 300x300 px.jpg"
      }
    },
  ]
})

onMounted(() => {
  cartStore.getContent()
})
</script>

<template>
  <div class="main-layout">
    <div>
      <header>
        <TopBar/>
        <Header/>
      </header>
      <SideMenu v-if="startupStore?.isMenuInitialised"/>
      <Quotation v-if="quotationStore?.isInit"/>
      <main>
        <slot/>
      </main>
    </div>
    <footer>
      <FooterBlock/>
    </footer>
  </div>
</template>
